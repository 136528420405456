// @flow

import React from "react";
import { graphql } from "gatsby";
import { observer, inject } from "mobx-react";
import { Provider } from "mobx-react";
import { observable, action } from "mobx";
import ReactResizeDetector from "react-resize-detector";

import styles from "./index.module.scss";
import Metadata from "../components/Metadata";
import UiStore from "../stores/ui-store";
import JsonStore from "../stores/json-store";
import { STORE_UI, STORE_JSON } from "../constants/stores";
import { JSON_HOME, JSON_GATE } from "../constants/json";
import FeedItem from "../components/FeedItem";
import type { FeedItemData } from "../components/FeedItem";

type IndexProps = {
  data: any,
  location: any,
  ui: UiStore,
  json: JsonStore,
};

@inject(STORE_UI, STORE_JSON)
@observer
class IndexPage extends React.Component<IndexProps, any> {
  stores: any;

  constructor(props: IndexProps, context: any) {
    super(props, context);
    this.props[STORE_JSON].setJsonFromGraphQL(this.props.data, JSON_HOME);
    this.props[STORE_JSON].setJsonFromGraphQL(this.props.data, JSON_GATE);
    this.props[STORE_UI].bgToggle(JSON_HOME);
  }

  componentDidMount() {
    if (window) {
      setTimeout(() => this.props[STORE_UI].checkAgeGate(), 300);
    }
    if (process.env.GATSBY_PREVIEW === "true" && window) {
      this.props.json.getData(JSON_HOME, {
        contentType: "ui",
        id: "6eX13vamRU8O8boGpwxC24",
      });
      this.props.json.getData(JSON_GATE, {
        contentType: "ui",
        id: "6eX13vamRU8O8boGpwxC24",
      });
    }
  }

  @observable width: number = 0;

  @action
  setWidth = (width: number): void => {
    this.width = width;
  };

  @observable feedHidden: boolean = true;

  @action
  setFeedVisible = (): void => {
    this.feedHidden = false;
  };

  handleResize = (width: number) => {
    if (width !== 0) {
      this.setWidth(width);
      setTimeout(() => this.setFeedVisible(), 100);
    }
  };

  render() {
    const data: any =
      this.props[STORE_JSON].store.get(JSON_HOME) || this.props.data;

    return (
      <Provider {...this.stores}>
        <>
          <Metadata
            data={this.props.data.home.homepageMetadata}
            location={this.props.location}
          />
          <div className={styles.wrapper}>
            <div
              className={styles.inner}
              style={{
                padding: this.props.ui.isMobile
                  ? `0 calc(${data.home.homepagePaddingMobile}rem / 16)`
                  : `0 calc(${data.home.homepagePadding}rem / 16)`,
                maxWidth: `${data.home.homepageMaxWidth}px`,
              }}
            >
              <ReactResizeDetector handleWidth onResize={this.handleResize} />
              {data.home.homepageFeed.map((feedItem: FeedItemData, index) => {
                return (
                  <FeedItem
                    data={feedItem}
                    hidden={this.feedHidden}
                    parentWidth={this.width}
                    ui={this.props.ui}
                    key={`HomepageFeed-${index}`}
                  />
                );
              })}
            </div>
          </div>
        </>
      </Provider>
    );
  }
}

export const query = graphql`
  query {
    gate: contentfulUi(contentful_id: { eq: "6eX13vamRU8O8boGpwxC24" }) {
      ageGateBackgrounds {
        color
        overlayColor
        image {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
        video {
          file {
            url
          }
        }
      }
    }
    home: contentfulUi(contentful_id: { eq: "6eX13vamRU8O8boGpwxC24" }) {
      homepageMaxWidth
      homepagePadding
      homepagePaddingMobile
      homepageFeed {
        breakLine
        marginBottom
        shiftHorizontal
        shiftVertical
        imageOrientation
        imageWidth
        imageWidthMobile
        videoAspectRatio
        video {
          file {
            url
          }
        }
        imageUrl
        image {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
      homepageBackground {
        overlayColor
        image {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
      homepageMetadata {
        title
        description {
          text: description
        }
        shareImage {
          file {
            url
          }
        }
      }
    }
  }
`;

export default IndexPage;
